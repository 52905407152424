
<form [formGroup]="form" (ngSubmit)="enviar()" *ngIf="!carregando" autocomplete="off">

  <div class="mb-3">
    <app-input-text class="w-100"
                    [label]="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ? 'CPF*' : 'CNPJ*'"
                    [placeholder]="''" [id]="'documento'"
                    [mask]="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ? '000.000.000-00' : '00.000.000/0000-00'"
                    [invalid]="form.controls.documento.invalid && !validarFormulario"
                    [msgAlerta]="(TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ? 'CPF' :  'CNPJ') + ' inválido ou vazio'"
                    formControlName="documento"
    ></app-input-text>
  </div>
  <div class="mb-3">
    <div class="row">
      <div [class.col-6]="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa" [class.col-12]="TipoProspeccao.Fisica !== novoParceiroService.model.tipoPessoa">
        <app-input-text class="w-100"
                        [label]="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ? 'Nome completo*' : 'Razão social*'"
                        [placeholder]="''" [id]="'nome'"
                        [invalid]="form.controls.nome.invalid && !validarFormulario"
                        [formControlName]="'nome'"
        ></app-input-text>
      </div>
      <div class="col-6" *ngIf="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ">
        <app-input-text class="w-100"
                        [label]="'Data de nascimento*'"
                        [tipoInput]="'date'"
                        [placeholder]="''" [id]="'dataNascimento'"
                        [msgAlerta]="validandoDataNascimento()"
                        [invalid]="form.controls.dataNascimento.invalid && !validarFormulario"
                        formControlName="dataNascimento"
        ></app-input-text>
      </div>
      <div class="col-12 mt-3" *ngIf="TipoProspeccao.Juridica === novoParceiroService.model.tipoPessoa">
        <div class="row">
          <div class="col-12">
            <app-input-text class="w-100"
                            [label]="'Nome para contato*'"
                            [placeholder]="''" [id]="'nomeContato'"
                            [invalid]="form.controls.nomeContato.invalid && !validarFormulario"
                            formControlName="nomeContato"
            ></app-input-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3" *ngIf="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa">
    <div class="row">
      <div class="col-6">
        <app-input-text class="w-100"
                        [label]="'RG'"
                        [placeholder]="''" [id]="'documentoRg'"
                        [required]="false"
                        [invalid]="form.controls.documentoRg.invalid && !validarFormulario"
                        formControlName="documentoRg"
                        [maxlength]="15"
        ></app-input-text>
      </div>
      <div class="col-6" *ngIf="fc['documentoRg'].value">
        <app-input-text class="w-100"
                        [label]="'Órgão emissor'"
                        [placeholder]="''" [id]="'orgaoEmissor'"
                        [required]="tornarCampoObrigatorio()"
                        [invalid]="form.controls.orgaoEmissor.invalid && !validarFormulario"
                        formControlName="orgaoEmissor"
        ></app-input-text>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="row">
      <div class="col-6">
        <app-input-text class="w-100"
                        [label]="'CEP*'"
                        [mask]="'00.000-000'"
                        [placeholder]="''" [id]="'cep'"
                        [invalid]="form.controls.cep.invalid && !validarFormulario"
                        (funcaoExterna)="buscarCep($event)"
                        formControlName="cep"
        ></app-input-text>
      </div>
      <div class="col-6">
        <app-input-text class="w-100"
                        [label]="TipoProspeccao.Fisica === novoParceiroService.model.tipoPessoa ? 'Endereço*' : 'Endereço da sede*'"
                        [placeholder]="''" [id]="'logradouro'"
                        [readonly]="enderecoEncontrado"
                        [invalid]="form.controls.logradouro.invalid && !validarFormulario"
                        formControlName="logradouro"
        ></app-input-text>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="row">
      <div class="col-4">
        <app-input-text class="w-100"
                        [label]="'Número*'"
                        [placeholder]="''" [id]="'numero'"
                        [invalid]="form.controls.numero.invalid && !validarFormulario"
                        formControlName="numero"
        ></app-input-text>
      </div>
      <div class="col-4">
        <app-input-text class="w-100"
                        [label]="'Complemento'"
                        [placeholder]="''" [id]="'complemento'"
                        [invalid]="form.controls.complemento.invalid && !validarFormulario"
                        formControlName="complemento"
        ></app-input-text>
      </div>
      <div class="col-4">
        <app-input-text class="w-100"
                        [label]="'Bairro*'"
                        [placeholder]="''" [id]="'bairro'"
                        [readonly]="enderecoEncontrado"
                        [invalid]="form.controls.bairro.invalid && !validarFormulario"
                        formControlName="bairro"
        ></app-input-text>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="row">
      <div class="col-6">
        <app-input-text class="w-100"
                        [label]="'Cidade*'"
                        [placeholder]="''" [id]="'cidade'"
                        [readonly]="enderecoEncontrado"
                        [invalid]="form.controls.cidade.invalid && !validarFormulario"
                        formControlName="cidade"
        ></app-input-text>
      </div>
      <div class="col-6">
        <app-input-select class="w-100" [label]="'UF*'" [id]="'uf'" [tipoNumber]="false" [placeholder]="''"
                          [readonly]="enderecoEncontrado"
                          [invalid]="form.controls.uf.invalid && !validarFormulario" [tipoSelectNumber]="false"
                          formControlName="uf" [list]="estados"></app-input-select>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="row">
      <div class="col-6">
        <button (click)="pularStep.emit(StepperPreCadastroParceiro.Voltar)" type="button" class="btn btn-outline-dark btn-block w-100 mb-3" id="voltar-pre-cadastro">
          Voltar
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-primary btn-block w-100 mb-3" id="enviar-dados-complementares">
          Continuar
        </button>
      </div>
    </div>
  </div>

</form>
