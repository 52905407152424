import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() id = null;
  @Input() tipoInput = 'text';
  @Input() placeholder = ''
  @Input() invalid = false;
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() label: string = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() mask;
  @Input() suffix = '';
  @Input() required = true;
  @Input() readonly = false;
  @Output() funcaoExterna = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() value: string;
  @Input() maxlength = null;
  @Input() set campoPreenchido(preenchido: boolean) {
   this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  disabled = false;

  ngOnInit() {}

  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.required && (this.onTouched(), control.value === undefined || control.value === null)) {
      return { required: true };
    }  else {
      if(this.required && (control.value?.toString()?.trim() === '')) {
        return { required: true };
      }
      return null;
    }
  }

  chamarExterno(value: any): void {
    if(value.trim()) {
      let inputValue = value.trim();
      if (inputValue === '') {
        this.value = '';
      }
      this.funcaoExterna.emit(value)
    }
  }

  removerNumeroString(input: string): string {
    if(input) {
      return input.replace(/^[0-9]+/, '');
    } else {
      return '';
    }
  }
}
